.review {
  padding: 40px;
  background: white;
  border-radius: 10px;
  width: 380px;
  font-size: 16px;
}

.review-name {}

.review-store {
  display: inline-block;
  margin-left: 10px;
  color: #9CA3AF;
}

.review-p {
  color: #867694;
  white-space: pre-line;
}