.invoicing {
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(109, 74, 211, 0.04);
  min-height: 100vh;
  width: 100%;
}

/* Invoicing password modal */
.invoicing-modal {
  padding-top: 60px;
  background: white;
  width: 990px;
  max-width: 100%;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}

.invoicing-modal_form {
  padding: 0 20px;
}

.invocing-modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(109, 74, 211, 0.1);
}

/**/

.invoicing_container {
  max-width: 1120px;
  margin: 42px auto 0 auto;
  padding: 0 15px;
}

.invoicing_container--dark {
  opacity: 0.2;
  filter: blur(5px);
  transition: 0.2s;
}

.invoicing_head {
  color: #49315E;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -2.26px;
  font-weight: 800;
  font-family: 'AvenirNext';
  margin: 0;
  padding-top: 80px;
}

.invoicing_subhead {
  font-size: 24px;
  color: #725F82;
  margin: 0;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.invoicing_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  align-items: flex-start;
  gap: 40px;
}

.invoicing_invoice {
  box-shadow: 0px 5.33403px 10.6681px -1.00013px rgba(184, 174, 192, 0.29), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.invoicing_bar {
  width: 2px;
  background: #D7D1DB;
  height: 360px;

}

.invocing_flexcontent {
  max-width: 305px;
  line-height: 100%;
}

.invoicing_ul__head {
  display: block;
  font-weight: 800;
  font-family: 'AvenirNext';
  color: #8059C8
}

.invoicing_ul {
  list-style-type: none;
  padding: 0;
  font-size: 16px;
  line-height: 125%;
  color: #725F82;
}

.invoicing_ul li {
  margin: 12px 0;
}

.invoicing_h2 {
  font-weight: 800;
  font-family: 'AvenirNext';
  color: #8059C8;
  letter-spacing: -2.04px;
  font-size: 32px;
  margin: 0;
  line-height: 100%;
}

.invoicing_ul__head {
  display: block;
  font-weight: 800;
  font-family: 'AvenirNext';
  color: #8059C8;
  position: relative;
  padding-left: 15px;
  letter-spacing: -0.5px;

}

.invoicing_ul__head::before {
  position: absolute;
  content: "";
  width: 11px;
  height: 9px;
  background: url('./checkmark.svg') no-repeat;
  top: 6px;
  left: 0;
}

@media screen and (min-width: 770px) {
  .invoicing-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    border-radius: 16px;
    min-height: 480px;
  }
}