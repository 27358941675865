.google-btn {
  border: 2px solid #5383EC;
  background: white;
  font-family: inherit;
  width: 400px;
  max-width: 100%;
  display: block;
  margin: 20px auto 0 auto;
  height: 60px;
  color: #5383EC;
  border-radius: 8px;
  font-size: 19px;
  cursor: pointer;
  transition: 0.3s;
}

.google-btn:hover {
  border-color: #997ad3;
  color: #997ad3;
}

.google-btn__icon {
  height: 20px;
  vertical-align: sub;
  padding-right: 8px;
}

.google-btn__error {
  margin: auto;
  display: block;
  color: rgb(241, 130, 141);
  width: 420px;
  max-width: 100%;
  text-align: center;
  padding: 10px;
}