.bottombanner {
  width: 100%;
  margin-top: 60px;
  position: relative;
  z-index: 3;
  transition: 0.5s;
  transition-delay: 0.1s;
  opacity: 1;
}
.bottombanner--hide {
  opacity: 0;
}
.bottombanner_content {
  max-width: 1190px;
  margin: auto;
}
.bottombanner_app{ 
  max-width: 346px;
  z-index: 8;
  position: relative;
}
.bottombanner_testimonial {
  position: relative;
  z-index: 8;
  display: none;
}
.bottombanner_bar{ 
  z-index: 8;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #f0eaf8;
}
@media screen and (min-width: 770px) {
  .bottombanner_testimonial {
    display: block;
  }
  .bottombanner_content {
    display: flex; 
    justify-content: space-around;
  }
}