.onboarding-flex {
  display: flex;
}

.onboarding-left {
  position: relative;
  width: calc(100% - 500px);
  padding: 0 60px;
  min-height: 100vh;
}

.onboarding-right {
  width: 500px;
  background-color: hsl(260, 55%, 96%);
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-right_logo {
  text-align: center;
  top: 60px;
  position: absolute;
  left: 0;
  width: 100%;
}

.badges-flex {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 60px;
  width: 100%;
  left: 0;
}

@media screen and (max-height: 740px) {
  .badges-flex {
    position: initial;
    margin-top: 20px;
  }

  .badges {
    opacity: 0;
  }
}

@media screen and (max-height: 770px) {
  .onboarding-right_logo {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .onboarding-right {
    width: 460px;
  }

  .rightpane-block {
    margin-left: 20px;
  }

  .onboarding-left {
    width: calc(100% - 460px);
    padding: 0 30px;
  }
}

@media screen and (max-width: 920px) {
  .onboarding-right {
    display: none;
  }

  .onboarding-left {
    width: 100%
  }
}