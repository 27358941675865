.form-raso_download {
  background: rgba(104, 63, 145, 0.1);
  border: 0;
  border-radius: 14px;
  padding: 16px 24px;
  color: #683f91;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid rgba(104, 63, 145, 0.12);
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.form-raso_download:hover {
  background: rgb(104, 63, 145, 0.2);
}

.form-raso_download_icon {
  height: 24px;
  vertical-align: middle;
  margin-right: 20px;
  opacity: 0.6
}
.form-raso_dlink {
  text-decoration: none;
}