.rightpane-block {
  padding: 40px;
  background: white;
  border-radius: 10px;
  margin-left: 50px;
  width: calc(100% - 50px);
  font-size: 16px;
  position: relative;
  min-height: 450px;
}

.rightpane-block-subheadline {
  text-transform: uppercase;
  color: #D7D1DB;
  letter-spacing: -0.5px;
  font-size: 18px;
  font-weight: 800;
  font-family: 'AvenirNext';
  display: block;
}

.rightpane-block-headline {
  color: #5D4870;
  font-size: 28px;
  letter-spacing: -0.75px;
  font-weight: 800;
  font-family: 'AvenirNext';
  display: block;
  padding: 8px 0;
  line-height: 110%;
}

.rightpane-block-p {
  white-space: pre-line;
  color: #725F82;
  letter-spacing: -0.75px;
  font-family: 'AvenirNext';
}

.rightpane-block-man {
  position: absolute;
  height: 220px;
  bottom: 0;
  right: 10px;
}

.rightpane-block-man2 {
  position: absolute;
  width: 149px;
  bottom: 100%;
  right: 10px;
}

.rightpane-block-p strong {
  font-weight: 800;
}

.rightpane-block-checkmark {
  position: absolute;
  height: 180px;
  bottom: -20px;
  right: 20px;
}

.rightpane-block-smallcheckmark {
  display: inline-block;
  vertical-align: sub;
  margin-left: 10px;
}

.rightpane-block-smallcheckmarkr {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.rightpane-block-bullets {
  margin-top: 20px;
}

.rightpane-block-bullet {
  color: #725F82;
  font-size: 18px;
  display: block;
  letter-spacing: -0.04em;
  margin-bottom: 8px;
}

.rightpane-block-sheet {
  width: 180px;
  box-shadow: 0 0 20px rgba(116, 129, 108, 0.2);
}

.rightpane-block-magnifier {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 260px;
}

.rightpane-block-trustmark {
  position: absolute;
  top: -110px;
  right: -10px;
  width: 160px;
}

@media screen and (max-width: 1024px) {
  .rightpane-block {
    margin-left: 30px;
    width: calc(100% - 30px);
  }
}

@media screen and (max-height: 860px) {
  .rightpane-block-man2 {
    position: absolute;
    width: 100px;
    bottom: 100%;
    right: 10px;
  }
}

@media screen and (max-height: 750px) {
  .rightpane-block-man2 {
    display: none;
  }
}