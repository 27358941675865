.form_submit_btn {
  position: relative;
  background: #905fda;
  border-radius: 25px;
  height: 48px;
  width: 100%;
  border: 0;
  color: white;
  padding: 0 25px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  line-height: 1rem;
  font-family: 'Assistant';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  display: inline-block;
}

@media screen and (min-width: 500px) {
  .form_submit_btn {
    width: auto;
  }
}

.form_submit_btn--inline {
  width: 100%;
  display: inline-block;
  height: 48px;
  font-weight: 400;
  min-width: fit-content;
  background: transparent;
  color: currentColor;
  border: 1px solid currentColor;
  font-size: inherit;
}

.form_submit_btn--classic {
  text-transform: initial;
  font-size: 14px;
  font-family: 'AvenirNext';
  padding: 0 35px;
}

.form_submit_btn--biglarge {
  width: 400px;
  max-width: 100%;
  height: 60px;
  border-radius: 8px;
  text-transform: initial;
  font-size: 20px;
}

@media screen and (min-width: 500px) {
  .form_submit_btn--inline {
    width: auto;
  }
}

.form_submit_btn:focus,
.form_submit_btn:hover {
  background-color: var(--color-primary_hover);
}

.form_submit_btn--inline:hover {
  color: var(--color-white);
}

.form_submit_btn--alt {
  background: none;
  border: 1px solid rgb(109, 74, 211);
  color: rgb(109, 74, 211);
}

.form_submit_btn.clear {
  background-color: transparent;
  color: rgb(109, 74, 211);
}

.button--loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 400px) {
  .form_submit_btn {
    min-width: 160px;
  }
}

.form_submit_btn--fluid {
  min-width: auto;
}

.form_submit_btn[disabled] {
  /* background-color: var(--color-grey_light-1); */
  cursor: not-allowed;
}

.button--img {
  margin: 0.25rem;
  max-height: 36px;
}

/************************/
/****** Animations ******/
/************************/

.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.lds-ellipsis {
  position: relative;
  width: 60px;
  height: 48px;
}

.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis--dark div {
  background: #747474;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
  animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(19px, 0);
    transform: translate(19px, 0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(19px, 0);
    transform: translate(19px, 0);
  }
}